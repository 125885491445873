
const server_ip = 'https://qa2-api.operrwork.com';
const v2_server_ip = 'https://qa2-api.operrwork.com';

export const environment = {
  production: false,
  server_backend: server_ip,
  server_ip: `${server_ip}/api`,
  v2_server_backend: `${v2_server_ip}`,
  v2_server_ip: `${v2_server_ip}`,
  ip_checking_site: 'https://api.ipify.org/?format=json',
  time_to_display_clock: 15,
  v2_api_url: 'https://qa2-api.operrwork.com/company/api/v2',
  login_url: 'https://qa2.operrwork.com/#/login',
};
